const development = {
    b3bnbAccountCookieApiV1Config: {
        URL: "http://ac.localhost.com:8002",
    },

    inboxBusinessRestaurantPrinterBrazilRestApiV1Config: {
        URL: "http://localhost:8047/v1",
    },

    inboxBusinessPosReportBrazilRestApiV1Config: {
        URL: "http://localhost:8048/v1",
    },
}

const production = {
    b3bnbAccountCookieApiV1Config: {
        URL: "https://accountcookie.b3bnb.com",
    },

    inboxBusinessRestaurantPrinterBrazilRestApiV1Config: {
        URL: "https://proxy-restaurant-api.b3bnb.com/inbox-business-restaurant-printer-brazil-rest-api/v1",
    },

    inboxBusinessPosReportBrazilRestApiV1Config: {
        URL: "https://proxy-restaurant-api.b3bnb.com/inbox-business-pos-report-brazil-rest-api/v1",
    },
}

export {
    development,
    production
}