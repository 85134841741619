import {
    graphqlInboxBusinessCollaboratorAccountBrazilApiV1, graphqlB3bnbCompanyBrazilApiV1,
    graphqlInboxBusinessPosBrazilApiV1
} from "../../../../../../infrastructure/api/graphql/graphql"
import { getTokenPOS } from "../../../../../../infrastructure/api/rest/cookie"
import {
    collaboratorAccountByTokenQuery, companyBrazilQuery,
    posCashRegisterOpenByCollaboratorAccountQuery
} from "./graphql/query"

async function getCookieTokenPOS() {
    return await getTokenPOS()
}

async function getCollaboratorAccountByToken(token) {
    return await graphqlInboxBusinessCollaboratorAccountBrazilApiV1(collaboratorAccountByTokenQuery, { token })
}

async function getCompanyBrazil(ID) {
    return await graphqlB3bnbCompanyBrazilApiV1(companyBrazilQuery, { ID })
}

async function getPosCashRegisterOpenByCollaboratorAccount(collaboratorAccountID) {
    return await graphqlInboxBusinessPosBrazilApiV1(posCashRegisterOpenByCollaboratorAccountQuery, { collaboratorAccountID })
}

const Controller = {
    getCookieTokenPOS,
    getCollaboratorAccountByToken,
    getCompanyBrazil,
    getPosCashRegisterOpenByCollaboratorAccount
}

export {
    Controller
}