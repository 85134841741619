import { applyMiddleware, compose, createStore } from "redux"
import { routerMiddleware } from "connected-react-router"
import reducers from "./ducks"

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export default (history) => createStore(
    reducers(history),
    composeEnhancer(
        applyMiddleware(
            routerMiddleware(history),
        ),
    ),
)
