const development = {
    b3bnbCompanyBrazilApiV1Config: {
        URI_GRAPHQL: "http://localhost:8010/v1/graphql"
    },

    inboxBusinessCollaboratorAccountBrazilApiV1Config: {
        URI_GRAPHQL: "http://localhost:8044/v1/graphql"
    },

    inboxBusinessProductSaleBrazilApiV1Config: {
        URI_GRAPHQL: "http://localhost:8022/v1/graphql"
    },

    inboxBusinessProductBrazilApiV1Config: {
        URI_GRAPHQL: "http://localhost:8013/v1/graphql"
    },

    inboxBusinessPaymentMethodBrazilApiV1Config: {
        URI_GRAPHQL: "http://localhost:8024/v1/graphql"
    },

    inboxBusinessSearchBrazilApiV1Config: {
        URI_GRAPHQL: "http://localhost:8007/v1/graphql"
    },

    inboxBusinessPosBrazilApiV1Config: {
        URI_GRAPHQL: "http://localhost:8045/v1/graphql"
    },

    inboxBusinessProductSaleNFCeTransactionBrazilApiV1Config: {
        URI_GRAPHQL: "http://localhost:8025/v1/graphql"
    },

    govNativeNFCeTransactionBrazilApiV1Config: {
        URI_GRAPHQL: "http://localhost:8009/v1/graphql"
    },

    inboxBusinessInvoiceReceivableBrazilApiV1Config: {
        URI_GRAPHQL: "http://localhost:8016/v1/graphql"
    },

    inboxBusinessRestaurantBrazilApiV1Config: {
        URI_GRAPHQL: "http://localhost:8046/v1/graphql"
    },

    inboxBusinessCustomerBrazilApiV1Config: {
        URI_GRAPHQL: "http://localhost:8019/v1/graphql"
    },

    inboxBusinessRestaurantKdsOrderBrazilApiV1Config: {
        URI_GRAPHQL: "http://localhost:8051/v1/graphql"
    },
}

const production = {
    b3bnbCompanyBrazilApiV1Config: {
        URI_GRAPHQL: "https://apis.b3bnb.com/b3bnb-company-brazil-graphql-api/v1/graphql"
    },

    inboxBusinessCollaboratorAccountBrazilApiV1Config: {
        URI_GRAPHQL: "https://apis.b3bnb.com/inbox-business-collaborator-account-brazil-graphql-api/v1/graphql"
    },

    inboxBusinessProductSaleBrazilApiV1Config: {
        URI_GRAPHQL: "https://apis.b3bnb.com/inbox-business-product-sale-brazil-graphql-api/v1/graphql"
    },

    inboxBusinessProductBrazilApiV1Config: {
        URI_GRAPHQL: "https://apis.b3bnb.com/inbox-business-product-brazil-graphql-api/v1/graphql"
    },

    inboxBusinessPaymentMethodBrazilApiV1Config: {
        URI_GRAPHQL: "https://apis.b3bnb.com/inbox-business-payment-method-brazil-graphql-api/v1/graphql"
    },

    inboxBusinessSearchBrazilApiV1Config: {
        URI_GRAPHQL: "https://apis.b3bnb.com/inbox-business-search-brazil-graphql-api/v1/graphql"
    },

    inboxBusinessPosBrazilApiV1Config: {
        URI_GRAPHQL: "https://apis.b3bnb.com/inbox-business-pos-brazil-graphql-api/v1/graphql"
    },

    inboxBusinessProductSaleNFCeTransactionBrazilApiV1Config: {
        URI_GRAPHQL: "https://apis.b3bnb.com/inbox-business-product-sale-nfce-transaction-brazil-graphql-api/v1/graphql"
    },

    govNativeNFCeTransactionBrazilApiV1Config: {
        URI_GRAPHQL: "https://apis.b3bnb.com/gov-native-nfce-transaction-brazil-graphql-api/v1/graphql"
    },

    inboxBusinessInvoiceReceivableBrazilApiV1Config: {
        URI_GRAPHQL: "https://apis.b3bnb.com/inbox-business-invoice-receivable-brazil-graphql-api/v1/graphql"
    },

    inboxBusinessRestaurantBrazilApiV1Config: {
        URI_GRAPHQL: "https://proxy-restaurant-api.b3bnb.com/inbox-business-restaurant-brazil-graphql-api/v1/graphql"
    },

    inboxBusinessCustomerBrazilApiV1Config: {
        URI_GRAPHQL: "https://apis.b3bnb.com/inbox-business-customer-brazil-graphql-api/v1/graphql"
    },

    inboxBusinessRestaurantKdsOrderBrazilApiV1Config: {
        URI_GRAPHQL: "https://proxy-restaurant-api.b3bnb.com/inbox-business-restaurant-kds-order-brazil-graphql-api/v1/graphql"
    },
}

export {
    development,
    production
}
