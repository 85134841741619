import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { createBrowserHistory } from "history"
import storeCreate from "./delivery/frontend/react/store/store"
import { App } from "./delivery/frontend/react/components/App/App"

const history = createBrowserHistory()
const store = storeCreate(history)

ReactDOM.render(<Provider store={store}><App history={history} /></Provider>, document.getElementById("root"))
