import { deleteCookieTokenPOS as restDeleteCookieTokenPOS } from "../../../../../../infrastructure/api/rest/cookie"

async function deleteCookieTokenPOS() {
    return await restDeleteCookieTokenPOS()
}

const Controller = {
    deleteCookieTokenPOS
}

export {
    Controller
}