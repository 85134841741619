const initialState = {
    company: null,
    collaboratorAccount: null,
    posCashRegister: null,
    isLogged: false
}

export const Types = {
    SESSION_LOGIN: 'session/SESSION_LOGIN',
    POS_CASH_REGISTER_ADD: 'session/POS_CASH_REGISTER_ADD',
    POS_CASH_REGISTER_DELETE: 'session/POS_CASH_REGISTER_DELETE',
    SESSION_LOGOUT: 'session/SESSION_LOGOUT',
}

export function sessionLogin(company, collaboratorAccount) {
    return {
        type: Types.SESSION_LOGIN,
        payload: {
            company,
            collaboratorAccount,
            isLogged: true
        }
    }
}

export function posCashRegisterAdd(posCashRegister) {
    return {
        type: Types.POS_CASH_REGISTER_ADD,
        payload: {
            posCashRegister,
        }
    }
}

export function posCashRegisterDelete() {
    return {
        type: Types.POS_CASH_REGISTER_DELETE,
    }
}

export function sessionLogout() {
    return {
        type: Types.SESSION_LOGOUT
    }
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case Types.SESSION_LOGIN:
            return {
                ...state,
                company: action.payload.company,
                collaboratorAccount: action.payload.collaboratorAccount,
                posCashRegister: action.payload.posCashRegister,
                isLogged: action.payload.isLogged
            }
        case Types.POS_CASH_REGISTER_ADD:
            return {
                ...state,
                posCashRegister: action.payload.posCashRegister,
            }
        case Types.POS_CASH_REGISTER_DELETE:
            return {
                ...state,
                posCashRegister: null,
            }
        case Types.SESSION_LOGOUT:
            return initialState
        default:
            return state
    }
}
