const collaboratorAccountByTokenQuery = `
query($token:String!){
    collaboratorAccountByToken(token:$token){
        collaboratorAccount{
            ID,
            name,
            companyID
        },
        error{
            code,
            message
        }
    }
}
`
const companyBrazilQuery = `
query($ID:ID!){
    companyBrazil(ID:$ID){
        companyBrazil{
            ID,
            fantasyName,
            document
        },
        error{
            code,
            message
        }
    }
}
`

const posCashRegisterOpenByCollaboratorAccountQuery = `
query($collaboratorAccountID:String!){
    posCashRegisterOpenByCollaboratorAccount(collaboratorAccountID:$collaboratorAccountID){
        posCashRegister{
            ID
        },
        error{
            code,
            message
        }
    }
}
`

export {
    collaboratorAccountByTokenQuery,
    companyBrazilQuery,
    posCashRegisterOpenByCollaboratorAccountQuery
}