import { production as restConfig } from "./config"
import nodeFetch from "node-fetch"

async function getToken() {
    const options = {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
            name: "token"
        })
    }

    return await (await nodeFetch(`${restConfig.b3bnbAccountCookieApiV1Config.URL}/v1/getCookie`, options)).json()
}

async function getTokenPOS() {
    const options = {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
            name: "tokenPOS"
        })
    }

    return await (await nodeFetch(`${restConfig.b3bnbAccountCookieApiV1Config.URL}/v1/getCookie`, options)).json()
}

async function createCookieTokenPOS(tokenPOS) {
    const options = {
        method: "POST",
        body: JSON.stringify({ name: "tokenPOS", value: tokenPOS }),
        credentials: "include"
    }

    return await (await nodeFetch(`${restConfig.b3bnbAccountCookieApiV1Config.URL}/v1/createCookie`, options)).json()
}

async function deleteCookieTokenPOS() {
    const options = {
        method: "DELETE",
        body: JSON.stringify({ name: "tokenPOS" }),
        credentials: "include",
    }

    return await (await nodeFetch(`${restConfig.b3bnbAccountCookieApiV1Config.URL}/v1/deleteCookie`, options)).json()
}


export {
    getToken,
    getTokenPOS,
    createCookieTokenPOS,
    deleteCookieTokenPOS
}
