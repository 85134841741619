import React from 'react'

function NavBarLogged({
    handleLogout }) {
    return (
        <nav className="navbar is-transparent" role="navigation" aria-label="dropdown navigation">
            <div className="navbar-brand">
                <a className="navbar-item">
                    <div style={{ fontSize: "18px", backgroundColor: "#265D26", padding: "2px 5px", color: "#fff" }}>B3BNB</div>
                    <div style={{ marginLeft: "5px", padding: "5px", color: "#000" }}>Inbox POS</div>
                    <button className="button is-small is-rounded" style={{ marginLeft: "10px", fontWeight: "bold" }} onClick={_ => handleLogout()}>
                        Sair
                    </button>
                </a>
            </div>
        </nav>
    )
}

export {
    NavBarLogged
}
