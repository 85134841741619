import React, { Suspense, lazy } from "react"
import { Switch, Route } from "react-router-dom"

let common = {
    AuthPage: lazy(() => import("../pages/common/AuthPage/AuthPage")),
    SelectCheckoutPage: lazy(() => import("../pages/common/SelectCheckoutPage/SelectCheckoutPage")),
    CheckNFCeEmissionPage: lazy(() => import("../pages/common/CheckNFCeEmissionPage/CheckNFCeEmissionPage")),
    LinkPOSLoginErrorPage: lazy(() => import("../pages/common/LinkPOSLoginErrorPage/LinkPOSLoginErrorPage")),
    SelectRestaurantKdsPage: lazy(() => import("../pages/common/SelectRestaurantKdsPage/SelectRestaurantKdsPage")),
    RestaurantKdsPage: lazy(() => import("../pages/common/RestaurantKdsPage/RestaurantKdsPage")),
    NoMatchPage: lazy(() => import("../pages/common/NoMatchPage/NoMatchPage"))
}

let desktop = {
    POSPage: lazy(() => import("../pages/desktop/POSPage/POSPage")),
    RestaurantTablePage: lazy(() => import("../pages/desktop/RestaurantTablePage/RestaurantTablePage"))
}

let mobile = {
    SelectRestaurantTablePage: lazy(() => import("../pages/mobile/SelectRestaurantTablePage/SelectRestaurantTablePage")),
    RestaurantTablePage: lazy(() => import("../pages/mobile/RestaurantTablePage/RestaurantTablePage"))
}

const Routes = () => (
    <Suspense fallback={<div>Carregando...</div>}>
        <Switch>
            {/* common */}
            <Route exact path="/login/:companyID" component={common.AuthPage} />
            <Route exact path="/selectCheckout" component={common.SelectCheckoutPage} />
            <Route exact path="/checkNFCeEmission/:productSaleID" component={common.CheckNFCeEmissionPage} />
            <Route exact path="/selectRestaurantKds" component={common.SelectRestaurantKdsPage} />
            <Route exact path="/restaurantKds/:restaurantKdsID" component={common.RestaurantKdsPage} />
            <Route exact path="/linkPOSLoginError" component={common.LinkPOSLoginErrorPage} />

            {/* desktop */}
            <Route exact path="/desktop/pos" component={desktop.POSPage} />
            <Route exact path="/desktop/restaurantTable" component={desktop.RestaurantTablePage} />

            {/* mobile */}
            <Route exact path="/mobile/selectRestaurantTable" component={mobile.SelectRestaurantTablePage} />
            <Route exact path="/mobile/restaurantTable/:restaurantTableID" component={mobile.RestaurantTablePage} />

            <Route exact path="*" component={common.NoMatchPage} />
        </Switch>
    </Suspense>
)

export { Routes }
