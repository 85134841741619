import React from "react"
import { useDispatch } from "react-redux"
import { sessionLogout } from "../../store/ducks/session"
import { useSelector } from "react-redux"
import { Controller } from "./controller"
import { NavBarRender } from "./NavBarRender"
import "./style.css"

function NavBar() {
    const dispatch = useDispatch()
    const isLogged = useSelector(state => state.session.isLogged)

    async function handleLogout() {
        await Controller.deleteCookieTokenPOS()
        dispatch(sessionLogout())
        window.location.href = "/login/" + sessionStorage.getItem("companyID")
    }

    return (
        <NavBarRender
            isLogged={isLogged}
            handleLogout={handleLogout} />
    )
}

export { NavBar }
