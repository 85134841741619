import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import { sessionLogin } from "../../store/ducks/session"
import { Controller } from "./controller"
import { AppRender } from "./AppRender"
import { getError } from "../../../../../infrastructure/api/graphql/graphql"

function App(props) {
    const dispatch = useDispatch()

    useEffect(() => {
        //dispatch(enableLoad())
        init()
    }, [dispatch])

    async function init() {
        const cookieTokenPOS = await Controller.getCookieTokenPOS()
        const companyID = sessionStorage.getItem("companyID")

        if (cookieTokenPOS && cookieTokenPOS.value !== "") {
            const collaboratorAccountByTokenResponse = await Controller.getCollaboratorAccountByToken(cookieTokenPOS.value)
            const errCollaboratorAccountByTokenResponse = getError(collaboratorAccountByTokenResponse)

            if (!errCollaboratorAccountByTokenResponse) {
                const collaboratorAccount = collaboratorAccountByTokenResponse.data.collaboratorAccountByToken.collaboratorAccount

                const companyBrazilResponse = await Controller.getCompanyBrazil(collaboratorAccount.companyID)
                const errCompanyBrazilResponse = getError(companyBrazilResponse)

                if (!errCompanyBrazilResponse) {
                    const company = companyBrazilResponse.data.companyBrazil.companyBrazil
                    sessionStorage.setItem("companyID", company.ID)

                    const posCashRegisterResponse = await Controller.getPosCashRegisterOpenByCollaboratorAccount(collaboratorAccount.ID)
                    const errPosCashRegisterResponse = getError(posCashRegisterResponse)

                    if (!errPosCashRegisterResponse) {
                        const posCashRegister = posCashRegisterResponse.data.posCashRegisterOpenByCollaboratorAccount.posCashRegister
                        dispatch(sessionLogin(company, collaboratorAccount, posCashRegister))

                        pageSelectCheckout()
                    }
                }
            } else if (companyID) {
                pageLoginPOS()
            } else {

            }
        } else if (companyID) {
            pageLoginPOS()
        } else {

        }
    }

    function pageLoginPOS() {
        if (!window.location.pathname.includes("/login/")) {
            props.history.push("/login/" + sessionStorage.getItem("companyID"))
        }
    }

    function pageSelectCheckout() {
        if (window.location.pathname.includes("/login/")) {
            props.history.push("/selectCheckout")
        }
    }

    return (
        <AppRender props={props} />
    )
}

export { App }
