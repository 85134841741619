import React from 'react'
import './style.css'

const Container = (props) => (
    <div>
        {props.children}
    </div >
)

export { Container }
