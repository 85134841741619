import axios from "axios"
import { production as graphqlConfig } from "./config"

async function graphqlRequest(uri, body, variables) {
    body = {
        query: body
    }

    if (variables) {
        body.variables = variables
    }

    return (await axios({
        method: 'post',
        url: uri,
        data: body
    })).data
}

async function graphqlB3bnbCompanyBrazilApiV1(body, variables) {
    try {
        return await graphqlRequest(graphqlConfig.b3bnbCompanyBrazilApiV1Config.URI_GRAPHQL, body, variables)
    } catch (error) {
        return getInternalServerError()
    }
}

async function graphqlInboxBusinessCollaboratorAccountBrazilApiV1(body, variables) {
    try {
        return await graphqlRequest(graphqlConfig.inboxBusinessCollaboratorAccountBrazilApiV1Config.URI_GRAPHQL, body, variables)
    } catch (error) {
        return getInternalServerError()
    }
}

async function graphqlInboxBusinessProductSaleBrazilApiV1(body, variables) {
    try {
        return await graphqlRequest(graphqlConfig.inboxBusinessProductSaleBrazilApiV1Config.URI_GRAPHQL, body, variables)
    } catch (error) {
        return getInternalServerError()
    }
}

async function graphqlInboxBusinessProductBrazilApiV1(body, variables) {
    try {
        return await graphqlRequest(graphqlConfig.inboxBusinessProductBrazilApiV1Config.URI_GRAPHQL, body, variables)
    } catch (error) {
        return getInternalServerError()
    }
}

async function graphqlInboxBusinessPaymentMethodBrazilApiV1(body, variables) {
    try {
        return await graphqlRequest(graphqlConfig.inboxBusinessPaymentMethodBrazilApiV1Config.URI_GRAPHQL, body, variables)
    } catch (error) {
        return getInternalServerError()
    }
}

async function graphqlInboxBusinessSearchBrazilApiV1(body, variables) {
    try {
        return await graphqlRequest(graphqlConfig.inboxBusinessSearchBrazilApiV1Config.URI_GRAPHQL, body, variables)
    } catch (error) {
        console.log(error)
        return getInternalServerError()
    }
}

async function graphqlInboxBusinessPosBrazilApiV1(body, variables) {
    try {
        return await graphqlRequest(graphqlConfig.inboxBusinessPosBrazilApiV1Config.URI_GRAPHQL, body, variables)
    } catch (error) {
        return getInternalServerError()
    }
}

async function graphqlInboxBusinessProductSaleNFCeTransactionBrazilApiV1(body, variables) {
    try {
        return await graphqlRequest(graphqlConfig.inboxBusinessProductSaleNFCeTransactionBrazilApiV1Config.URI_GRAPHQL, body, variables)
    } catch (error) {
        return getInternalServerError()
    }
}

async function graphqlGovNativeNFCeTransactionBrazilApiV1(body, variables) {
    try {
        return await graphqlRequest(graphqlConfig.govNativeNFCeTransactionBrazilApiV1Config.URI_GRAPHQL, body, variables)
    } catch (error) {
        return getInternalServerError()
    }
}

async function graphqlInboxBusinessInvoiceReceivableBrazilApiV1(body, variables) {
    try {
        return await graphqlRequest(graphqlConfig.inboxBusinessInvoiceReceivableBrazilApiV1Config.URI_GRAPHQL, body, variables)
    } catch (error) {
        return getInternalServerError()
    }
}

async function graphqlInboxBusinessRestaurantBrazilApiV1(body, variables) {
    try {
        return await graphqlRequest(graphqlConfig.inboxBusinessRestaurantBrazilApiV1Config.URI_GRAPHQL, body, variables)
    } catch (error) {
        return getInternalServerError()
    }
}

async function graphqlInboxBusinessCustomerBrazilApiV1(body, variables) {
    try {
        return await graphqlRequest(graphqlConfig.inboxBusinessCustomerBrazilApiV1Config.URI_GRAPHQL, body, variables)
    } catch (error) {
        return getInternalServerError()
    }
}

async function graphqlInboxBusinessRestaurantKdsOrderBrazilApiV1(body, variables) {
    try {
        return await graphqlRequest(graphqlConfig.inboxBusinessRestaurantKdsOrderBrazilApiV1Config.URI_GRAPHQL, body, variables)
    } catch (error) {
        return getInternalServerError()
    }
}

function getInternalServerError() {
    const error = { message: 'Erro de comunicação' }
    return error
}

function getError(obj) {
    //error graphql
    if (obj.errors) {
        return getInternalServerError()
        //erro network
    } else if (obj.message) {
        return getInternalServerError()
        // error infrastructure
    } else if (obj.data[Object.keys(obj.data)[0]].error) {
        return obj.data[Object.keys(obj.data)[0]].error
    }

    return null
}


export {
    getError,
    graphqlB3bnbCompanyBrazilApiV1,
    graphqlInboxBusinessCollaboratorAccountBrazilApiV1,
    graphqlInboxBusinessProductSaleBrazilApiV1,
    graphqlInboxBusinessProductBrazilApiV1,
    graphqlInboxBusinessPaymentMethodBrazilApiV1,
    graphqlInboxBusinessSearchBrazilApiV1,
    graphqlInboxBusinessPosBrazilApiV1,
    graphqlInboxBusinessProductSaleNFCeTransactionBrazilApiV1,
    graphqlGovNativeNFCeTransactionBrazilApiV1,
    graphqlInboxBusinessInvoiceReceivableBrazilApiV1,
    graphqlInboxBusinessRestaurantBrazilApiV1,
    graphqlInboxBusinessCustomerBrazilApiV1,
    graphqlInboxBusinessRestaurantKdsOrderBrazilApiV1
}
