import React from 'react'
import { NavBarLogged } from './NavBarLogged'

const NavBarRender = ({
    isLogged,
    handleLogout }) => {
    if (isLogged === true) {
        return (<NavBarLogged
            handleLogout={handleLogout} />)
    } else {
        return (<></>)
    }
}

export {
    NavBarRender
}
